var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-card',{staticClass:"primary-shade--light pa-3"},[_c('h1',{staticClass:"text-center subtitle-4 black--text"},[_vm._v("فاتورة ( "+_vm._s(_vm.$route.params.name)+" )")]),_c('v-row',[_c('v-spacer'),_c('v-col',{attrs:{"md":"3","cols":"12"}},[_c('div',{staticClass:"d-flex flex-row"},[_c('v-text-field',{attrs:{"label":"البحث","outlined":"","dense":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.search()}},model:{value:(_vm.table.search),callback:function ($$v) {_vm.$set(_vm.table, "search", $$v)},expression:"table.search"}}),_c('button',{staticClass:"search-btn",on:{"click":function($event){return _vm.search()}}},[_c('v-icon',{staticStyle:{"font-size":"18px","color":"white"}},[_vm._v(" fa-search ")])],1)],1)])],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.table.headers,"loading-text":"جاري التحميل ... الرجاء الانتظار","items":_vm.table.Data,"options":_vm.tableOptions,"server-items-length":_vm.table.totalData,"loading":_vm.table.loading,"footer-props":{
            itemsPerPageOptions: [10, 50, 100],
          }},on:{"update:options":function($event){_vm.tableOptions=$event}},scopedSlots:_vm._u([{key:"item._id",fn:function(props){return [_vm._v(" "+_vm._s(_vm.table.totalData - props.index - (_vm.tableOptions.page - 1) * _vm.tableOptions.itemsPerPage)+" ")]}},{key:"item.price",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.price))+" ")]}},{key:"item.total_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.total_amount))+" ")]}},{key:"item.payment_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.payment_amount))+" ")]}},{key:"item.discount_amount",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.discount_amount))+" ")]}},{key:"item.remaining",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.numberWithComma(item.remaining))+" ")]}},{key:"item.tooth_place",fn:function(ref){
          var item = ref.item;
return [(item.tooth_place)?_c('span',[_vm._v(_vm._s(item.tooth_place)+" - "+_vm._s(item.tooth_number))]):_vm._e()]}},{key:"item.xray_image",fn:function(ref){
          var item = ref.item;
return [(item.xray_image)?_c('v-img',{staticClass:"teacher_image_table",attrs:{"src":_vm.content_url + item.xray_image,"width":"50","height":"50"},on:{"click":function($event){return _vm.showImage(item.xray_image)}}}):_vm._e()]}},{key:"item.is_printed",fn:function(ref){
          var item = ref.item;
return [(item.is_printed)?_c('v-chip',{attrs:{"color":"success","size":"small"}},[_vm._v("تمت الطباعة")]):_c('v-chip',{attrs:{"color":"#00695C"}},[_vm._v("لم تتم الطباعة")])]}},{key:"item.creator_name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.creator_name)+" ("+_vm._s(item.creator_type_ar)+") ")]}},(_vm.center_id !== _vm.$store.state.s_and_s_clink_id)?{key:"item.received_by_name",fn:function(ref){
          var item = ref.item;
return [_vm._v(" "+_vm._s(item.received_by_name)+" ")]}}:null],null,true)})],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogData.open),callback:function ($$v) {_vm.$set(_vm.dialogData, "open", $$v)},expression:"dialogData.open"}},[_c('v-toolbar',{attrs:{"color":_vm.dialogData.color,"dense":""}}),_c('v-card',[_c('v-card-title',{staticClass:"headline justify-center"},[_vm._v(" "+_vm._s(_vm.dialogData.bodyText)+" ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1 justify-start","text":""},on:{"click":function($event){_vm.dialogData.open = false}}},[_vm._v(" تم ")])],1)],1)],1),_c('v-dialog',{attrs:{"max-width":"600px"},model:{value:(_vm.table.showImageDialog),callback:function ($$v) {_vm.$set(_vm.table, "showImageDialog", $$v)},expression:"table.showImageDialog"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"headline"},[_vm._v("عرض الصورة")])]),_c('v-card-text',[_c('v-container',[_c('v-form',[_c('v-row',{staticClass:"justify-center"},[_c('img',{attrs:{"src":_vm.content_url + _vm.table.imageUrlForShow,"alt":"","width":"300","height":"200"}})])],1)],1)],1),_c('v-card-actions',{staticClass:"ml-3"},[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.table.showImageDialog = false}}},[_vm._v(" الغاء ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }