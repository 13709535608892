<template>
  <div>
    <v-card class="primary-shade--light pa-3">
      <h1 class="text-center subtitle-4 black--text">فاتورة ( {{ $route.params.name }} )</h1>
      <v-row>
        <v-spacer></v-spacer>
        <v-col md="3" cols="12">
          <div class="d-flex flex-row">
            <v-text-field v-model="table.search" label="البحث" outlined dense @keyup.enter="search()"></v-text-field>
            <button class="search-btn" @click="search()">
              <v-icon style="font-size: 18px; color: white"> fa-search </v-icon>
            </button>
          </div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table :headers="table.headers" loading-text="جاري التحميل ... الرجاء الانتظار" :items="table.Data"
            :options.sync="tableOptions" :server-items-length="table.totalData" :loading="table.loading"
            class="elevation-1" :footer-props="{
              itemsPerPageOptions: [10, 50, 100],
            }">
            <template slot="item._id" slot-scope="props">
              {{ table.totalData - props.index - (tableOptions.page - 1) * tableOptions.itemsPerPage }}
            </template>
            <template v-slot:item.price="{ item }">
              {{ numberWithComma(item.price) }}
            </template>
            <template v-slot:item.total_amount="{ item }">
              {{ numberWithComma(item.total_amount) }}
            </template>
            <template v-slot:item.payment_amount="{ item }">
              {{ numberWithComma(item.payment_amount) }}
            </template>
            <template v-slot:item.discount_amount="{ item }">
              {{ numberWithComma(item.discount_amount) }}
            </template>
            <template v-slot:item.remaining="{ item }">
              {{ numberWithComma(item.remaining) }}
            </template>
            <template v-slot:item.tooth_place="{ item }">
              <span v-if="item.tooth_place">{{ item.tooth_place }} - {{ item.tooth_number }}</span>
            </template>
            <template v-slot:item.xray_image="{ item }">
              <v-img :src="content_url + item.xray_image" width="50" height="50" v-if="item.xray_image"
                @click="showImage(item.xray_image)" class="teacher_image_table" />
            </template>
            <template v-slot:item.is_printed="{ item }">
              <v-chip color="success" v-if="item.is_printed" size="small">تمت الطباعة</v-chip>
              <v-chip color="#00695C" v-else>لم تتم الطباعة</v-chip>
            </template>
            <template v-slot:item.creator_name="{ item }">
              {{ item.creator_name }} ({{ item.creator_type_ar }})
            </template>
            <template v-slot:item.received_by_name="{ item }" v-if="center_id !== $store.state.s_and_s_clink_id">
              {{ item.received_by_name }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
    </v-card>
    <!--- dialog for show info to user-->
    <v-dialog v-model="dialogData.open" max-width="500px">
      <v-toolbar :color="dialogData.color" dense></v-toolbar>
      <v-card>
        <v-card-title class="headline justify-center"> {{ dialogData.bodyText }} </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1 justify-start" text @click="dialogData.open = false"> تم </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
    <v-dialog v-model="table.showImageDialog" max-width="600px">
      <v-card>
        <v-card-title>
          <span class="headline">عرض الصورة</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-form>
              <v-row class="justify-center">
                <img :src="content_url + table.imageUrlForShow" alt width="300" height="200" />
              </v-row>
            </v-form>
          </v-container>
        </v-card-text>
        <v-card-actions class="ml-3">
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="table.showImageDialog = false"> الغاء </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- image Teacher dialog -->
  </div>
</template>

<script>
import customersApi from '@/api/customer'
import salary_other_daily from '@/api/salary_other_daily'
import sectionApi from '@/api/section_other'
import servicesApi from '@/api/service_other'
import { getCurrentDateInString } from '@/constant/date'
import numberWithComma from '@/constant/number'
import { mdiCash, mdiCashMultiple } from '@mdi/js'
import { isAllowedToDelete } from '@/constant/isAllowed'
import employeeApi from '@/api/employee'

export default {
  data() {
    return {
      rate: 0,

      today_statistics: {
        price: 0,
        payment_amount: 0,
        discount_amount: 0,
        remaining: 0,
      },

      account_type: null,

      errors: {},

      employee: {
        data: [],
        loading: false,
        selected: {},
        salary: null,
      },

      login_store: {
        type: null,
      },

      tooth_place_items: ['UL', 'UR', 'LL', 'LR'],

      account_type: null,

      menuStartDate: false,

      menuEndDate: false,

      icons: {
        mdiCashMultiple,
        mdiCash,
        mdiCashMultiple,
      },

      menuNextPayment: false,

      content_url: null,

      menuDate: false,

      dialogData: {
        open: false,
        color: 'primary',
        bodyText: 'test',
      },

      tableOptions: {},

      customers: {
        data: [],
        loading: false,
      },

      services: {
        data: [],
        loading: false,
      },

      sections: {
        data: [],
        loading: false,
      },

      nextPaymentDisable: false,

      otherData: {
        currencySymbol: ' IQD',
        payment_amount: 0,
        price: 0,
        discount_amount: 0,
      },

      center_id: null,

      xray_image: null,

      xray_image_edit: null,

      imgAndDivShowControll: {
        IsDiv1Showing: true,
        isImg1Showing: false,

        IsDiv1ShowingEdit: true,
        isImg1ShowingEdit: false,

        IsDiv2Showing: true,
        isImg2Showing: false,

        IsDiv3Showing: true,
        isImg3Showing: false,
      },

      table: {
        search: null,
        totalData: 0,
        Data: [],
        loading: true,
        showImageDailog: false,
        imageUrlForShow: null,

        showImageDialog: false,

        headers: [
          {
            text: '#',
            align: 'start',
            sortable: false,
            value: '_id',
          },
          {
            text: 'اسم الزبون',
            sortable: false,
            value: 'customer_name',
          },
          {
            text: 'نوع الخدمة',
            sortable: false,
            value: 'service_name',
          },
          {
            text: 'الهاتف',
            sortable: false,
            value: 'customer_mobile',
          },
          { text: 'مبلغ الخدمة', sortable: false, value: 'price' },
          { text: 'الكمية', sortable: false, value: 'quantity' },
          { text: 'المبلغ الكلي', sortable: false, value: 'total_amount' },
          { text: 'المبلغ المدفوع', sortable: false, value: 'payment_amount' },
          { text: 'الخصم', sortable: false, value: 'discount_amount' },
          { text: 'المتبقي', sortable: false, value: 'remaining' },
          { text: 'تاريخ الفاتورة', sortable: false, value: 'date' },
          { text: 'الطباعة', sortable: false, value: 'is_printed' },
          { text: 'رقم - موقع السن', sortable: false, value: 'tooth_place' },
          { text: 'صورة الاشعة', sortable: false, value: 'xray_image' },
          { text: 'الموظف', sortable: false, value: 'received_by_name' },
          { text: 'الملاحظات', sortable: false, value: 'desc' },
          { text: 'اضيف بواسطة', sortable: false, value: 'creator_name' },
        ],
      },
    }
  },
  watch: {
    tableOptions: {
      handler() {
        this.getDataAxios()
      },
      deep: true,
    },
    '$route.query.search': {
      handler(search) {
        this.table.search = search
        this.getDataAxios(search)
      },
      deep: true,

      // immediate: true,
    },
    '$vuetify.breakpoint': {
      handler() {
        if (this.$vuetify.breakpoint.xs) {
          this.isScreenXs = true
        } else {
          this.isScreenXs = false
        }
      },
      deep: true,
    },
  },

  created() {
    this.getDataAxios()

    const results = JSON.parse(localStorage.getItem('results'))
    this.login_store.type = results.type

    if (results.type !== 'عيادة اسنان') {
      this.table.headers.splice(12, 2)
    }

    const account_type = this.$store.state.results.account_type

    this.account_type = account_type
  },

  methods: {
    async getDataAxios() {
      let { search } = this.table
      this.table.loading = true
      let { page, itemsPerPage } = this.tableOptions
      if (!search) {
        search = null
      }

      if (!page) {
        page = 1
      }
      if (!itemsPerPage) {
        itemsPerPage = 10
      }

      const response = await salary_other_daily.getEditedLog({
        id: this.$route.params.id,
        page,
        limit: itemsPerPage,
        search,
      })

      if (response.status === 401) {
        this.$store.dispatch('submitLogout')
      } else if (response.status === 500) {
        this.table.loading = false
        this.showDialogfunction('حصلت مشكلة يرجى المحاولة مجددا', '#FF5252')
      } else {
        this.table.loading = false
        this.table.Data = response.data.results.data
        this.table.totalData = response.data.results.count
        this.content_url = response.data.content_url
      }
    },

    showImage(image) {
      this.table.showImageDialog = true
      this.table.imageUrlForShow = image
    },

    search() {
      this.$router.replace(
        {
          query: { search: this.table.search },
        },
        () => {},
      )
    },

    imageToBase64(file) {
      if (file) {
        if (file.name) {
          return new Promise((resolve, reject) => {
            const reader = new FileReader()
            reader.readAsDataURL(file)
            reader.onload = () => resolve(reader.result)
            reader.onerror = error => reject(error)
          })
        } else {
          return file
        }
      }
    },

    showDialogfunction(bodyText, color) {
      this.dialogData.open = true
      this.dialogData.bodyText = bodyText
      this.dialogData.color = color
    },

    isAllowedToDelete,

    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },

    async isPrintedDB(id) {
      await salary_other_daily.isPrinted(id)
    },

    numberWithComma,
  },
}
</script>
